.btn-primary {
  background-color:#5a0000;
  border-color: #282c34;
}

.bg-dark {
  background-color: #5a0000 !important;
}

.navbar-dark .navbar-toggler {
  color: #ced0d2;
  border-color: #ced0d2;
}

.jumbotron {
  padding: 15px 30px 15px 30px;
}
