.App {
}

.App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 30vmin;
  pointer-events: none;
  margin-top: 50px;
}

.App-jumbotron {
  background-color: #282c34;
}


.App-infos {
  max-width: 600px;
  display: inline-block;
  padding: 0px 15px 0px 15px;
}

.App-infos h2 {
  padding: 25px 0px 0px 0px;
}

.App-center {
  text-align: center;
}

.App-vpad {
  margin-top: 35px;
  margin-bottom: 35px;
}

.dip {
  background-color: #bf1919;
  display: inline-block;
  padding: 1vw;
}

.dip-switch-on {
  display: inline-block;
  border-style: solid;
  border-color: #631631 #bf1919 #bf1919 #bf1919;
  border-width: 4vw 0vw 0vw 0vw;
  margin: 2vw;
  background-color: white;
  width: 3vw;
  height: 10vw;
  margin: 1vw;
}

.dip-switch-off {
  display: inline-block;
  border-style: solid;
  border-color: #bf1919 #bf1919 #631631 #bf1919;
  border-width: 0vw 0vw 3vw 0vw;
  background-color: white;
  width: 3vw;
  height: 10vw;
  margin: 1vw;
}

.dip-label {
  display: inline-block;
  border-style: solid;
  border-color: #bf1919;
  border-width: 1vw;
  color: white;
  font-weight: bold;
  width: 5vw;
  height: 3vw;
  margin: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputFile + label {
  color: white;
  background-color: #5a0000;
  border-radius: 3px;
  padding: 6px 12px 6px 12px;
  cursor: pointer;
}

.message {
  display: block;
  border-radius: 0px;
  font-size: 1.25em;
  width: 100%;
  height: 20vh;
  position: relative;
  border: 0px;
  margin: 0px 0px 10px 0px;
}

.preview {
  display: block;
  border-radius: 0px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.contentContainer {
  width: 75vw;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  background-color: #ced0d2;
  padding: 10px;
}

.starClick {
  height: 30vmin;
  margin-top: 50px;
}

.moreCharsLeft {
  color: green
}

.noMoreCharsLeft {
  color: red
}





html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App-container {
  min-height: 100%;
  position: relative;
}

.App-body {
  padding-bottom: 120px;
}

.App-footer {
  position: absolute;
  padding-top: 20px;
  bottom: 0px;
  width: 100%;
  background-color: #282c34;
  color: #ced0d2;
  height: 120px;
}

.App-footer a {
  color: #ced0d2;
  font-weight: bold;
}

#root {
  height: 100%;
}
